"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Action = void 0;
var Action;
(function (Action) {
    Action["CLICK"] = "click";
    Action["IMPRESSION"] = "impression";
    Action["POPUP_OPEN"] = "popup_open";
    Action["POPUP_CLOSE"] = "popup_close";
    Action["TIME_SPENT"] = "time_spent";
    Action["NON_INTERACTIVE"] = "non_interactive";
    Action["RENDERED"] = "rendered";
    Action["COMPLETED"] = "completed";
    Action["CHECK"] = "check";
    Action["UNCHECK"] = "uncheck";
    Action["SELECT"] = "select";
    Action["CHANGE"] = "change";
    Action["SLIDER_CHANGE"] = "slider_change";
    Action["CLEAR"] = "clear";
})(Action = exports.Action || (exports.Action = {}));
