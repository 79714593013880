import { IStudent, StudentField } from "@studyportals/studentdomain";
import {
	IAcademicBackground,
	IBudgetPreference,
	IExperiment,
	IStudyInterests,
	IUser,
	IWishlistItem
} from "@/types";

import {
	AcademicBackground,
	BudgetPreference,
	StudyInterests
} from "@/domain";

export class User implements IUser {

	public userId?: string;
	public gender?: string;
	public age?: number;
	public nationality?: string;
	public countryOfResidence?: number;
	public wishlist: IWishlistItem[] = [];
	public abTests: IExperiment[] = [];
	public studyInterests: IStudyInterests | null = null;
	public budgetPreference: IBudgetPreference | null = null;
	public academicBackground: IAcademicBackground | null = null;
	public newsletterAccepted?: boolean;
	public englishLevel?: string;
	public isLoggedIn?: boolean;
	public isStudyportalsEmployee?: boolean;


	public setSessionData(isLoggedIn: boolean, isStudyportalsEmployee: boolean, id?: string): void {
		this.userId = id;
		this.isLoggedIn = isLoggedIn;
		this.isStudyportalsEmployee = isStudyportalsEmployee;
	}

	public setStudentData(data?: IStudent): void {
		if (!data) {
			return;
		}

		this.gender = data[StudentField.GENDER];
		this.age = data.birth_date ? this.calculateAge(data[StudentField.BIRTH_DATE]) : undefined;
		this.nationality = data[StudentField.NATIONALITY_COUNTRY_ISO];
		this.countryOfResidence = data[StudentField.RESIDENCE_COUNTRY_ID];
		this.newsletterAccepted = data[StudentField.EMAILING_FAVOURITES];
		this.englishLevel = data[StudentField.PROFICIENCY_TYPE];

		this.setStudyInterests(data);
		this.setBudgetPreference(data);
		this.setAcademicBackground(data);
	}

	public setWishlist(wishlist: IWishlistItem[]): void {
		this.wishlist = wishlist;
	}

	private setStudyInterests(data: IStudent): void {
		this.studyInterests = new StudyInterests(
			data[StudentField.INTERESTS_DISCIPLINES],
			data[StudentField.INTERESTS_COUNTRIES],
			data[StudentField.START_PERIOD_DATE],
			data[StudentField.ATTENDANCE]
		);
	}

	private setBudgetPreference(data: IStudent): void {
		this.budgetPreference = new BudgetPreference(
			data[StudentField.CURRENCY],
			data[StudentField.TUITION_BUDGET]?.amount,
			data[StudentField.LIVING_BUDGET]?.amount
		);
	}

	private setAcademicBackground(data: IStudent): void {
		this.academicBackground = new AcademicBackground(
			data[StudentField.STUDY_LEVEL],
			data[StudentField.STUDY_COUNTRY_ID],
			data[StudentField.ORIGIN_ORGANISATION_ID],
			data[StudentField.DISCIPLINES],
			data[StudentField.GPA] ? data[StudentField.GPA]['current_type'] as string : undefined,
			data[StudentField.GPA] ? data[StudentField.GPA]['self_assessment_grade'] as string : undefined,
			data[StudentField.WORK_EXPERIENCE]
		);
	}

	private calculateAge(dateOfBirth: string): number {
		const dob: Date = new Date(dateOfBirth);
		const now: Date = new Date();
		const diff: number = now.getTime() - dob.getTime();

		return Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
	}
}
