"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListName = void 0;
var ListName;
(function (ListName) {
    ListName["SEARCH_PAGE"] = "search_page";
    ListName["SCHOLARSHIP_SEARCH_PAGE"] = "scholarship_search_page";
    ListName["STUDY_CARD_STUDY_OPTIONS"] = "study_card_study_options";
    ListName["RECOMMENDER"] = "recommender";
    ListName["UNIVERSITY_HIGHLIGHTED"] = "university_highlighted";
    ListName["UNIVERSITY_PROGRAMMES"] = "university_programmes";
    ListName["STUDY_CARD_COUNTRY_DEGREE"] = "study_card_country_degree";
    ListName["STUDY_CARD_DISCIPLINE"] = "study_card_discipline";
    ListName["WISHLIST"] = "wishlist";
    ListName["META_RANKING"] = "meta_ranking";
})(ListName = exports.ListName || (exports.ListName = {}));
